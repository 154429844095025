<template>
  <div class="default" v-if="data">
    <BackBtn class="default__back" />
    <div class="default-inner--large">
      <div class="default-content">
        <div class="default-content-inner">
          <h1 v-html="data.title"></h1>
          <div v-html="data.htmltext"></div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Error />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { page } from '@/guides/endpoints/page'
import { useRoute } from 'vue-router'
import BackBtn from '@/components/BackBtn.vue'
import Error from '@/components/Error.vue'

export default defineComponent({
  name: 'libraryDetails',
  components: {
    BackBtn,
    Error,
  },
  setup() {
    const data = ref()
    const route = useRoute()
    const slug = computed(() => route?.params?.slug)

    const fetch = () => {
      if (!slug.value) {
        return
      }

      page(slug.value).then(r => {
        console.log('page', r)
        data.value = r.data.content
      })
    }

    watch(() => slug.value, fetch)

    fetch()

    return {
      data,
    }
  },
})
</script>

<style lang="scss" scoped>
.default {
  position: relative;
  background-color: $c-white;
}

[class*='default-inner--'],
.default-inner {
  position: relative;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  padding: $spacing * 3 0;
  z-index: 0;

  @include mq(m) {
    width: calc(100% - #{$spacing * 4});
  }

  &[class*='--large'] {
    max-width: 60rem;
    @include mq(m) {
      max-width: 72rem;
    }
  }
}

[class*='default-content-inner--'],
.default-content-inner {
  margin: 0 $spacing * 1.35;
  @include mq(m) {
    margin: 0;
  }
}

.default-content {
  ::v-deep(h1),
  ::v-deep(h2),
  ::v-deep(h3) {
    margin-top: $spacing * 1.8;
  }

  ::v-deep(h4),
  ::v-deep(h5) {
    margin-top: $spacing * 1.5;
  }

  ::v-deep(ul) {
    list-style: none;
    padding: 0;
    margin: $spacing * 1.5 0;
  }

  ::v-deep(li) {
    padding-left: 16px;

    & + li {
      margin-top: $spacing / 2;
    }

    ul {
      margin-top: 1rem;
      margin-left: 2rem;
    }
  }

  ::v-deep(li::before) {
    content: '\2022';
    color: $tangerine;
    display: inline-block;
    width: 6px;
    margin-left: -16px;
    margin-right: $spacing / 2;
  }

  ::v-deep(button) {
    width: auto;
  }

  ::v-deep(img) {
    margin-bottom: $spacing / 2;
  }

  ::v-deep(a) {
    color: $regal-blue;
    text-decoration: underline;
  }
}
</style>
